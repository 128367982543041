$(window).on('load', function(){
  if ($('.js-slider-one').length) {
    var sliderHeight = $('.js-slider-one').height();
    $(window).on('scroll', function(){
      if ($(window).scrollTop() > (sliderHeight - 40)) {
        $('.js-header-lower').addClass('header-lower--visible');
      } else {
        $('.js-header-lower').removeClass('header-lower--visible');
      }
    });
  }
});