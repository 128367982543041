const $ = window.jQuery;
const Lazyembed = require('./lazyembed.js');
module.exports = {
  initialize: function (options) {
    let that = this;
    this.$ = function (selector) {
      return jQuery(selector, options.el)
    }
    this.$postsContainer = this.$('[data-lm-container]');
    this.$trigger = this.$('[data-lmp-trigger]');
    this.$emptySearch = this.$('[data-empty-search]');
    this.$form = this.$('[data-filter-form]');
    this.reqData = this.$trigger.data();
    this.selectedTopic = null;
    this.selectedType = null;
    this.selectedYear = null;
    this.selectedRegulator = null;
    this.selectedIndustry = null;
    this.searchterm = (this.reqData.searchterm) ? this.reqData.searchterm : '';

    const params = new URLSearchParams(window.location.search);


    if (!this.reqData.hasMore) {
      this.$trigger.hide();
    }

    this.$form.on('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.getPosts(true);
      return false;
    });

    jQuery('[data-filter-topics]').on('change', (e) => {
      this.selectTopic(e);
    })

    let topic = params.get('topic');
    if (topic) {
      this.selectedTopic = topic;
      jQuery('[data-filter-topics]').val(topic);
      this.getPosts(true);
    }

    jQuery('[data-filter-types]').on('change', (e) => {
      this.selectType(e);
    })
    jQuery('[data-filter-search]').on('change', (e) => {
      this.setSearchterm(e);
    })

    jQuery('[data-filter-regulator]').on('change', (e) => {
      this.selectRegulator(e);
    });

    jQuery('[data-filter-industry]').on('change', (e) => {
      this.selectIndustry(e);
    });

    // handle enter
    jQuery('[data-filter-search]').on('keypress', (e) => {
      if (e.which === 13) {
        e.preventDefault();
        console.log('enter');
        return false;
      }
    });

    jQuery('[data-filter-search]').bind('typeahead:change', (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.setSearchterm(e);

    });

    jQuery('[data-lmp-trigger]').on('click', (e) => {
      this.loadMore(e);
    })
    jQuery('[data-filter-years]').on('change', (e) => {
      this.selectDate(e);
    })
  },
  setSearchterm: function (e) {
    e.preventDefault();
    let val = e.currentTarget.value;
    this.searchterm = val;
    this.getPosts(true);
  },
  selectDate: function (e) {
    let val = e.currentTarget.value;
    this.selectedYear = val;
    this.getPosts(true);
  },
  selectRegulator: function (e) {
    let val = e.currentTarget.value;
    this.selectedRegulator = val;
    this.getPosts(true);
  },
  selectIndustry: function (e) {
    let val = e.currentTarget.value;
    this.selectedIndustry = val;
    this.getPosts(true);
  },
  selectTopic: function (e) {
    let val = e.currentTarget.value;
    this.selectedTopic = val;
    this.getPosts(true);
  },
  selectType: function (e) {
    let val = e.currentTarget.value;
    this.selectedType = val;

    if (val === 'podcast') {
      {
        this.$('[data-filter-topics]').attr('disabled', 'disabled');
      }
    } else {
      this.$('[data-filter-topics]').removeAttr('disabled');
    }

    this.getPosts(true);
  },
  loadMore: async function () {
    this.$postsContainer.addClass('loading');
    this.$trigger.addClass('loading');
    const url = window.tpdata.base_url_kd + this.reqData.ep;
    let data = Object.assign({}, this.reqData, {
      topic: this.selectedTopic,
      date: this.selectedYear,
      offset: this.reqData.offset,
      type: this.selectedType,
      searchterm: this.searchterm,
      ppp: 3,
    });

    const req = await $.ajax(url, {
      data: data
    });
    this.$postsContainer.removeClass('loading');
    this.$trigger.removeClass('loading');
    this.$postsContainer.append(req.out);
    this.reqData.offset = this.reqData.offset + req.length;
    setTimeout(function () {
      Lazyembed.initialize({trigger: true});
    }, 5)
    if (req.postsLeft) {
      this.$trigger.show();
    } else {
      this.$trigger.hide();
    }
  },
  async getPosts(replace = false) {
    this.$postsContainer.addClass('loading');
    this.$trigger.addClass('loading');
    this.$emptySearch.hide();

    const url = window.tpdata.base_url_kd + this.reqData.ep;
    let data = Object.assign({}, this.reqData, {
      topic: this.selectedTopic,
      regulator: this.selectedRegulator,
      industry: this.selectedIndustry,
      date: this.selectedYear,
      offset: 0,
      type: this.selectedType,
      searchterm: this.searchterm
    })

    const params = new URLSearchParams(window.location.search);
    params.set('topic', this.selectedTopic);
    window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));

    this.reqData = data;

    const req = await $.ajax(url, {
      data: data
    });
    console.log('loading');
    if (replace) {
      this.$postsContainer.empty().append(req.out)
      this.reqData.offset = req.length;
    } else {
      this.$postsContainer.append(req.out);
      this.reqData.offset = this.reqData.offset + req.length;
    }

    if (req.length === 0) {
      this.$emptySearch.show();
    }

    setTimeout(function () {
      Lazyembed.initialize({trigger: true});
    }, 5)
    this.$postsContainer.removeClass('loading');
    this.$trigger.removeClass('loading');
    if (req.postsLeft) {
      this.$trigger.show();
    } else {
      this.$trigger.hide();
    }

  }
};
