// The lines below are executed on page load
$('.js-label').each(function() {
  if ($(this).val().length > 0) {
  	$(this).addClass('input-has-value');
  } else {
  	$(this).removeClass('input-has-value');
  }
});
// The lines below (inside) are executed on change & keyup
$('.js-label').on('change keyup', function() {
  if ($(this).val().length > 0) {
  	$(this).addClass('has-value');
  } else {
  	$(this).removeClass('has-value');
  } 
});