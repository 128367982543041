if (navigator.platform == 'Win32') {
  $("body").addClass("js-plattform-windows");
} else {
  $("body").removeClass("js-plattform-windows");
}
console.log(navigator.platform);


// Slider One
var counter = 0;


var windowPos = $(window).scrollTop();
var fullHeightWindow = $(window).height();
console.warn('windowpos ' + windowPos);
if ($(window).width() > 1023 && windowPos == 0 && !navigator.userAgent.match(/Trident\/7\./)) {
  var sliders = $('.js-slider-one');
  if (sliders.length > 0) {
    $('body.home').addClass('js-body-noscroll');
  }
}

$('.js-slider-one').on('init', function () {
  if ($(window).width() > 1023) {

    $('.js-slidercontainer').each(function () {
      var fullHeight = $(window).height();
      var headerHeight = $('.js-header-upper').outerHeight();
      $(this).height(fullHeight - headerHeight);
    });

    var counter = 0;


    $(document).on('click', '.js-slick-arrow-one .slick-next', function () {
      setTimeout(function () {
        if (counter < 2) {
          counter;
          counter++
          console.log(counter);
          console.log('next');
        }
      }, 1200);
    });
    $(document).on('click', '.js-slick-arrow-one .slick-prev', function () {
      setTimeout(function () {
        if (counter > 0 && counter <= 2) {
          counter;
          counter--;
          console.log(counter);
          console.log('prev');
        }
      }, 1200);
    });

    $('body.home').on('mousewheel DOMMouseScroll', function (e) {
      if (counter < 2) {
        console.warn(counter);
        clearTimeout($.data(this, 'scrollTimer'));
        $.data(this, 'scrollTimer', setTimeout(function () {
          if (typeof e.originalEvent.detail == 'number' && e.originalEvent.detail !== 0) {
            if (e.originalEvent.detail > 0) {
              if (counter < 2) {
                $('.js-slider-one').slick('slickNext');
              }
              counter++;
              console.log(counter);
              console.log('down');
            } else if (e.originalEvent.detail < 0) {
              $('.js-slider-one').slick('slickPrev');
              console.log('up');
              if (counter == 0) {
                counter = 0;
              } else {
                counter--;
              }
            }
          } else if (typeof e.originalEvent.wheelDelta == 'number') {
            if (e.originalEvent.wheelDelta < 0) {
              if (counter < 2) {
                $('.js-slider-one').slick('slickNext');
              }
              counter++;
              console.log('down');
              console.log(counter);
            } else if (e.originalEvent.wheelDelta > 0) {
              $('.js-slider-one').slick('slickPrev');
              console.log('up');
              if (counter == 0) {
                counter = 0;
                console.log('zeeero');
                console.log(counter);
              } else {
                counter--;
                console.log(counter);
              }
            }
          }
        }, 150));
      } else {
        $('body.home').removeClass('js-body-noscroll');
        $('body.home').off('mousewheel DOMMouseScroll');
        return;
      }
    });
    var fullHeight = $(window).height();
    var headerHeight = $('.js-header-upper').outerHeight();
    var totalEndHeight = fullHeight - headerHeight
    $('.js-hero-button').on('click', function (e) {
      e.preventDefault();
      $('html, body').stop().animate({
        'scrollTop': totalEndHeight
      }, 900);
      $('body.home').removeClass('js-body-noscroll');
      $('body.home').off('mousewheel DOMMouseScroll');
      return;
    });
  }

  if ($(window).width() <= 1023) {
    var imageHeight = $('.js-slider-one img').height()

    $('.js-slick-arrow-one').css({'top': (imageHeight / 2) - 25});
    var resizeTimer;
    $(window).on('resize', function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        var imageHeight = $('.js-slider-one img').height()
        $('.js-slick-arrow-one').css({'top': (imageHeight / 2) - 25});
      }, 250);
    });
  }
});


$('.js-slider-three').on('init', function () {
  if ($(window).width() <= 1023) {
    var imageHeightThree = $('.js-slider-three img').height()
    console.log(imageHeightThree);
    $('.js-slider-three .slick-arrow').css({'top': (imageHeightThree / 2) - 25});
    var resizeTimer;
    $(window).on('resize', function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        var imageHeightThree = $('.js-slider-three img').height()
        $('.js-slider-three .slick-arrow').css({'top': (imageHeightThree / 2) - 25});
      }, 250);
    });
  }
});


$(window).on('load', function () {
  $('.js-slider-one').slick({
    speed: 650,
    infinite: false,
    vertical: false,
    arrows: true,
    appendArrows: '.js-slick-arrow-one',
    prevArrow: '<button id="head-slider-prev" type="button" class="slick-prev">Previous</button>',
    nextArrow: '<button id="head-slider-next" type="button" class="slick-next">Next</button>',
    cssEase: 'cubic-bezier(.7,.01,.34,1)',

    // cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          vertical: false,
          speed: 500,
          infinite: true
        }
      }
    ]
  });
// Slider Two
  $('.js-slider-two').slick({
    arrows: true,
    prevArrow: '<button id="topic-slider-prev" type="button" class="slick-prev">Previous</button>',
    nextArrow: '<button id="topic-slider-next" type="button" class="slick-next">Next</button>',
    responsive: [
      {
        breakpoint: 640,
        settings: {
          arrows: false,
          adaptiveHeight: true,
          swipe: false
        }
      }
    ]
  });

  $('.js-slider-six').slick({
    arrows: true,
    prevArrow: '<button id="topic-slider-prev" type="button" class="slick-prev">Previous</button>',
    nextArrow: '<button id="topic-slider-next" type="button" class="slick-next">Next</button>',
    slidesToShow: 3,
    responsive: [

      {
        breakpoint: 640,
        settings: {
          arrows: true,
          adaptiveHeight: true,
          swipe: true,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1000,
        settings: {
          arrows: true,
          slidesToShow: 2,
          adaptiveHeight: true,
          swipe: true
        }
      }
    ]
  });

// Slider Three
  $('.js-slider-three').slick({
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  })


// Slider Four

  $('.js-slider-four').slick({
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    appendArrows: '.js-slick-arrow-four'
  });

// Slider Five
  $('.js-slider-five').slick({
    arrows: true,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          adaptiveHeight: true,
          swipe: false
        }
      }
    ]
  });


});

// Mouse animation

if ($(window).width() > 1024) {
  $(window).on('load', function () {
    $('.js-slidercontainer').each(function () {
      $(this).find('img').mouseParallax({moveFactor: 2, targetContainer: $(this)});
    });
  });
}

$(window).on('load', function () {


  var total = $(".js-slider-three").slick("getSlick").slideCount;
  var random = Math.floor(Math.random() * total) + 1;
  console.log(total);
  console.log(random);
  $('.js-slider-three').slick('slickGoTo', random);

  var select = $('.js-select-toggle');
  $('.js-select-toggle').change(function () {
    goTo = select.val();
    console.log(goTo);
    $('.js-slider-two').slick("goTo", goTo - 1);
  });

});

