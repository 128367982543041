module.exports = {
  initialize: function (options) {
    this.isLoading = false;
    this.$ = function (selector) {
      return jQuery(selector, 'body')
    }
    this.$input = this.$('input[name="s"]');
    this.$typeahead = this.$('#typeahead');

    let tags = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: {url: window.tpdata.suggest_tags, ttl: 6000, cache: false},
      remote: {
        url: window.tpdata.remote_suggest,
        rateLimitWait: 1000,
        prepare: function (query, settings) {
          settings.url = window.tpdata.remote_suggest + '?q=' + query;
          return settings;
        },
        transform: function (res){
          return res;
        }
      },
      identify: function (datum) {
        return datum.id;
      }
    });

    let $in = jQuery('.typeahead-in');

    $in.typeahead({
        hint: false,
        highlight: true,
        minLength: 0
      },
      {
        name: 'tags',
        display: 'title',
        limit: 20,
        source: tags,
        templates: {
          notFound: function (query) {
            return '';
          },
          suggestion: function (data) {
            return '<div class="search-suggestion">' +
              '<div class="search-suggestion-name"><span>' + data.type + '</span>' + data.title + '</div>' +
              '</div>';
          }
        }
      });

    $in.keypress(function (e) {
      if (e.which == 13) {
        let $in = jQuery('.tt-input');
        $in.val(jQuery(e.currentTarget).val());
        jQuery('body').addClass('is-searching')
        jQuery('#search').submit();
        return true;
      }
    });

    $in.on('typeahead:asyncrequest', function (e,a,query){
      if (query === '' || typeof query === 'undefined'){
        return;
      }
      jQuery('body').addClass('is-searching');
    })

    $in.on('typeahead:asyncreceive typeahead:asynccancle', function (){
      jQuery('body').removeClass('is-searching');

    });

    $in.bind('typeahead:select', function (e, sugg) {
      jQuery('body').addClass('is-searching')
      window.location = sugg.link;
      jQuery('.typeahead-in').typeahead('close');
      // that.$input.val(sugg.title);
      // that.$input.blur();
    })

    this.isOpen = false;
  }


};