
// Scroll to Anchor
// if ($('a[href*="#"]').length) {
// 	$('a[href*="#"]').on('click',function(e) {
// 		e.preventDefault();
// 		var target = this.hash;
// 		var $target = $(target);
// 		$('html, body').stop().animate({
// 			'scrollTop': $target.offset().top - 70
// 		}, 900, 'swing', function () {
// 			window.location.hash = target;
// 		});
// 	});
// } 

$('.box--two a').attr('target', '_blank');

