var freezeVp = function(e) {
	e.preventDefault();
};
$(document).on('click', '.js-search', function(e){
	e.preventDefault();
	$('.js-search-overlay').toggleClass('overlay--visible');
	$('body').addClass('js-body-noscroll');
	$('.form--search').find('.input').focus();
	var vpH = window.innerHeight;
	document.documentElement.style.height = vpH.toString() + "px";
	body.style.height = vpH.toString() + "px";
});
$(document).on('click', '.js-search-close', function(e){
	e.preventDefault();
	$('.js-search-overlay').removeClass('overlay--visible');
	$('body').removeClass('js-body-noscroll');
	document.body.removeEventListener("touchmove", freezeVp, false);
	body.style.height = 'auto';

});

$(document).on('click', '.js-hs', function(e){
	e.preventDefault();
	$('.js-hs-overlay').toggleClass('overlay--visible');
	$('body').addClass('js-body-noscroll');

});
$(document).on('click', '.js-hs-close', function(e){
	e.preventDefault();
	$('.js-hs-overlay').removeClass('overlay--visible');
	$('body').removeClass('js-body-noscroll');
	var vpH = window.innerHeight;
	document.documentElement.style.height = vpH.toString() + "px";
	body.style.height = 'auto';
});