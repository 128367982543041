module.exports = function (options) {
  return {
    initialize: function () {
      this.$el = jQuery(options.el)
      this.$parent = this.$el.closest('[data-lm-wrap]');
      this.$trigger = this.$parent.find('[data-lm-trigger]');
      this.triggerData = this.$trigger.data();
      this.containerData = this.$el.data();
      this.render(); // setup elements
      return this;
    },
    setData: function (data) {
      this.containerData = Object.assign({}, this.containerData, data);
    },
    addEvent: function () {
      let that = this;
      this.$trigger.addClass('lm-active');
      this.$trigger.on('click', function (e) {
        console.log('click');
        e.preventDefault();
        that.loadMore();
      })
    },
    render: function () {
      this.isLoading = false;
      if (this.triggerData && this.containerData) {
        if (!this.containerData.hasmore) {
          this.$trigger.hide();
        }
        this.addEvent();
      }
    },
    setTag: function (tag) {
      this.containerData.tag = tag;
    },
    setElement: function (element){
      this.$el = jQuery(element);
    },
    setTopic: function (topic) {
      this.containerData.topic = topic;
    },
    setSearchString: function (s) {
      this.containerData.searchString = s;
    },
    loadMore: function () {
      let that = this;
      if (this.isLoading) {
        return false;
      }

      let req = this.ajax().then(function (res) {
        that.isLoading = false;
        that.containerData.hasmore = res.postsLeft;
        that.$el.append(res.out);
        that.containerData.offset = parseInt(res.length, 10) + that.containerData.offset;

        if (!res.postsLeft) {
          that.$trigger.hide();
        }

        setTimeout(function () {
          new Foundation.Equalizer(jQuery('#redraw'), {});
        }, 12)
      })

    },
    ajax: function () {
      let baseUrl = window.tpdata.base_url + "kd/v1" + this.triggerData.api + '?' + Date.now();
      let that = this;
      let data = jQuery.extend({}, this.containerData, this.triggerData);
      return jQuery.ajax({
        url: baseUrl, beforeSend: function () {
          that.isLoading = true;
        }, data: data
      })
    }
  }
};