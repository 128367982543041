jQuery(document).ready(function ($) {
  let $body = $('body');
  $('[data-nav-target]').on('mouseenter', function (e) {
    let id = $(this).data('nav-target');
    $(`[data-navid]`).removeClass('active');
    $(`[data-navid=${id}]`).addClass('active');
  })

  $('.search-toggle').on('click', function () {
    let $this = $(this);
    $body.toggleClass('search-open');
    if ($this.data('active') !== true){
      $('.typeahead-in').focus();
    } else{
      $('.typeahead-in').blur();
    }


  })
  if ($body.hasClass('touch-device') && $(window).width() > 1170) {
    let $parents = $('ul.topics-list > li');
    $parents.on('click', function (e) {
      if ($(this).hasClass('active') === false) {
        e.preventDefault();
      }
      $parents.removeClass('active');
      $(this).addClass('active');
    })

    let $subtopics = $('ul.subtopics > li');
    $subtopics.on('click', function (e) {
      if ($(this).hasClass('active') === false) {
        e.preventDefault();
      }
      $subtopics.removeClass('active');
      $(this).addClass('active');
    })
  }


})

