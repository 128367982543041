$('body.home .module-pos-2').attr('id', 'js-content');

if ($('body.single-kdtopic').length && $(window).width() < 640) {
  prefooter = $('.js-prefooter');
  prefooter.addClass('prefooter--toggled');
}
if ($('.js-content-math').length) {
  var content = $('.js-content'),
  autoHeight = content.css('height', 'auto').height(),
  curHeight = content.height(),
  mathHeight = autoHeight * .25;
  $('.js-content').css('height', mathHeight);
}

$(document).on('click', '.js-content-extend', function (e) {
  var content = $('.js-content'),
  curHeight = content.height(),
  autoHeight = content.css('height', 'auto').height(),
  prefooter = $('.js-prefooter');
  prefooter.addClass('prefooter--toggled');
  content.height(curHeight).animate({height: autoHeight}, 300);
});

$("[data-bg]").each(function (i, el) {
  var data = $(el).data('bg');
  if (data !== ''){
    $('.page-wrap').css('background-image', 'url('+data+')');
  }
});

if ($(window).width() > 639 || $('.js-slider-four').length) {
  $(window).on('load resize', function checkHeight(){
    $('.js-height-parent').each(function(){  
      var highestBox = 0;
      $('.js-height-child', this).each(function(){
        if($(this).outerHeight() > highestBox) {
          highestBox = $(this).outerHeight(); 
        }
      });
      $('.js-height-child',this).outerHeight(highestBox);
    });
  });
}

if(navigator.userAgent.match(/Trident\/7\./)) {
  $('body.home').removeClass('js-body-noscroll');
  document.body.addEventListener("mousewheel", function() {
    event.preventDefault();
    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}
if (/Edge\/\d./i.test(navigator.userAgent)){
  $('body.home').removeClass('js-body-noscroll');
  document.body.addEventListener("mousewheel", function() {
    event.preventDefault();
    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}