module.exports = {
  initialize: function (options) {
    let that = this;
    let activeCookieGroups = [];
    if (options && options.trigger) {
      window.dispatchEvent(new Event('optanon'));
    }

    jQuery(window).on('optanon', function () {
      let groups = window.OptanonActiveGroups || '';
      let gArray = groups.split(',');
      that.activeCookieGroups = gArray.filter(function (i) {
        return i !== '';
      })
    })

    if (typeof that.activeCookieGroups === 'undefined') {
      that.activeCookieGroups = [];
    }
    that.addListener();
  },
  addListener: function () {
    let that = this;
    const config = {
      // If the image gets within 50px in the Y axis, start the download.
      rootMargin: '59px',
      threshold: 1, // half of item height
      trackVisibility: true,
      delay: 100
    };
    this.items = jQuery('[data-embed]').not('[data-initialized]');
    let observer = new IntersectionObserver(function (entries) {
      that.onIntersetion(entries);
    }, config);
    this.items.each((i, item) => {
      observer.observe(item);
    });

    jQuery('[data-interactive]').on('click', function () {
      let $el = jQuery(this);
      $el.parent().removeClass('post-video-embed-uninitialized');
      that.renderEmbed($el);
    })

    jQuery('.post-video-embed[data-interactive]').each(function (i, el) {
      $parent = jQuery(el).parent();
      if (typeof $parent.attr('class') === 'undefined') {
        $parent.addClass('post-video-embed-uninitialized');
      }
    })
  },
  onIntersetion: function (entries) {
    var that = this;
    entries.forEach(entry => {
      // console.log(entry);
      // Are we in viewport?
      if (entry.intersectionRatio > 0.5 && entry.isIntersecting) {
        let $el = jQuery(entry.target);
        if ($el.attr('data-interactive') === 'true') {
          return;
        }
        this.renderEmbed($el);
      }
    });
  },
  renderEmbed($el) {

    let cookie = [];
    let elCookie = $el.data('cookie')
    let accepted = true
    let that = this;
    if (elCookie) {
      cookie = elCookie.split(',');
      cookie.map(function (i) {
        if (that.activeCookieGroups.indexOf(i) === -1) {
          accepted = false
        }
      })
      if (that.activeCookieGroups.length === 0) {
        accepted = false;
      }
    }

    if (accepted === false) {
      $el.addClass('privacy-placeholder cookie-blocked').closest('.article-teaser').addClass('cookie-blocked');
      return;
    } else {
      $el.removeClass('privacy-placeholder cookie-blocked').closest('.article-teaser').removeClass('cookie-blocked');

    }

    if ($el.attr('data-initialized') === 'true') {
      return;
    }
    let embed = $el.data('embed');
    $el.attr('data-initialized', true);
    $el.parent().removeClass('post-video-embed-uninitialized');
    $el.html(embed);
  }
};