module.exports = function (options) {
  return {
    $: function(selector){
      return jQuery(selector, this.$el)
    },
    initialize: function () {
      this.isLoading = false;
      this.$el = jQuery(options.el);
      this.$parent = jQuery('[data-filter-wrap]',this.$el);
      this.$tags = jQuery('[data-filter-tags]', this.$el);
      this.selectedTopic = '';
      this.selectedEditor = jQuery('[data-editor]',this.$el).data('editor') || '';
      this.selectedTag = '';
      this.$currentTag = null;
      this.cb = options.initLoadMore;
      this.loadmore = options.loadmore;
      this.isOpen = false;
      this.renderTagToggle();
      this.loadFromQueryString();
    },
    events: {
      'click .tag': 'handleTag',
      'change select ': 'handleTopic',
      'click .filter-reset': 'reset',
      'click .js-tag-toggle': 'toggleTags'
    },
    reset: function () {
      this.$('select').prop('selectedIndex', 0);
      this.$('.tag').removeClass('tag--one').addClass('tag--two');
      this.selectedTopic = '';
      this.setTag('');
      this.loadMore();
      if (this.loadmore) {
        this.loadmore.containerData.offset = 7;
      }
    },
    toggleTags: function (e) {
      var that = this;
      if (this.$toggleContainer.data('open')) {
        this.$toggleContainer.removeClass('tags-vis').delay(200).slideToggle(150, function () {
          that.$toggleContainer.data('open', !that.$toggleContainer.data('open'))
          that.isOpen = that.$toggleContainer.data('open');
        });
      } else {
        this.$toggleContainer.slideToggle(150, function () {
          that.$toggleContainer.addClass('tags-vis').data('open', !that.$toggleContainer.data('open'));
          that.isOpen = that.$toggleContainer.data('open');
        });
      }
      var text = $(e.currentTarget).text();
      $(e.currentTarget).text(text == "Alle Kategorien" ? "Weniger Kategorien" : "Alle Kategorien");
    },
    handleTag: function (e) {
      var that = this;
      e.preventDefault();
      var $el = $(e.currentTarget);
      if (this.$currentTag) {
        this.$currentTag.removeClass('tag--one').addClass('tag--two');
      }
      this.$currentTag = $el;
      $el.toggleClass('tag--two').toggleClass('tag--one');
      this.setTag($el.data('tag'));
      _.defer(function () {
        that.loadMore();
      })
    },
    setTag: function (tag) {
      this.selectedTag = tag;
      if (this.loadmore) {
        this.loadmore.setTag(tag);
      }
    },
    scrollToFirst: function () {

      if (Foundation.MediaQuery.is('small only')) {
        $('html, body').animate({scrollTop: ($('[data-tag="' + this.selectedTag + '"]').offset().top - 70)}, 'fast');
      }
    },
    renderTagToggle: function () {

      if (this.$('.tag').length < 10) {
        return;
      }

      var sliced = this.$('.tag').slice(8).detach();
      $('<div class="js-tag-more"></div><br><span class="tag-toggle js-tag-toggle">Alle Kategorien</span>').appendTo(this.$tags);
      this.$toggleContainer = this.$('.js-tag-more');
      this.$toggleTrigger = this.$('.js-tag-toggle');
      this.$toggleContainer.append(sliced);
      if (!this.isOpen) {
        this.$toggleContainer.slideUp().data('open', false);
        this.$toggleTrigger.text('Alle Kategorien')
      } else {
        this.$toggleContainer.data('open', true);
        this.$toggleContainer.addClass('tags-vis');
        this.$toggleTrigger.text('Weniger Kategorien')
      }
    },
    loadFromQueryString: function () {
      var tag = this.getParameterByName('tag');
      if (typeof tag === 'String') {
        var $el = this.$('[data-tag="' + tag + '"]');
        if (this.$currentTag) {
          this.$currentTag.removeClass('tag--one').addClass('tag--two');
        }
        this.$currentTag = $el;
        $el.toggleClass('tag--two').toggleClass('tag--one');
        this.setTag($el.data('tag'))
        this.$toggleTrigger.trigger('click');
      }
    },
    getParameterByName: function (name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    handleTopic: function (e) {
      this.selectedTopic = $(e.currentTarget).val();
      if (this.loadmore) {
        this.loadmore.setTopic($(e.currentTarget).val())
      }
      this.loadMore();
    },
    loadMore: function () {
      var that = this;
      if (this.isLoading) {
        return false;
      }
      var req = this.ajax().then(function (res) {
        that.isLoading = false;
        that.$parent.empty().append(res.out);
        that.$tags.empty().append(res.tags);
        _.defer(function () {
          that.renderTagToggle();
          that.$('[data-tag="' + that.selectedTag + '"]').addClass('tag--one').removeClass('tag--two');
          if (that.loadmore) {
            that.loadmore.setElement(that.$('[data-lm-container]').eq(0));
            _.defer(function () {
              that.loadmore.render();
              that.scrollToFirst();
            })
            if ($(window).width() < 640) {
              $('html, body').animate({scrollTop: ($('[data-tag="' + that.selectedTag + '"]').offset().top - 70)}, 'fast');
            }
          }
        });
      })
    },
    ajax: function () {
      var getUrl = window.location;
      var baseUrl = window.tpdata.base_url + "kd/v1/filter/";
      var that = this;
      var data = {'topic': this.selectedTopic, 'tag': this.selectedTag, 'editor': this.selectedEditor};
      return jQuery.ajax({
        url: baseUrl,
        beforeSend: function () {
          that.isLoading = true;
        },
        data: data
      })
    }
  }
}